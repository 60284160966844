//---------------------------
//
// ## Body typography styles
// Styling of a post's body typography
//
//---------------------------

.post {	
	// Text
	p, li, dl, h4, h5, h6, figcaption, summary {
		line-height: $large-line-height;
		
		@include media($medium-screen) {
			line-height: $medium-line-height;
		}

		@include media($small-screen) {
			line-height: $small-line-height;
		}
	}
	
	// Inline code
	code.highlighter-rouge {
		padding: .1em;
		border-radius: .15em;

		background-color: #ddd;
	}
	
	// Various other elements
	hr {
		border-top: 0;
		border-bottom: 1px solid $light-grey;
		width: 20%;
	}

	// Do not add extra margins between list items with paragraphs:
	li p:first-child {
		margin-top: 0;
	}

	ol {
		padding-left: 80px;
	}
	
	img {
		display: block;
		margin: 1em auto;
		max-width: 100%;
	}

	figure {
		img {
			margin-bottom: 0.5em;
		}
		figcaption {
			text-align: center;
			font-style: italic;
			color: $dark-grey;
			padding: 0 1em;
			@include media($small-screen) {
				padding: 0;
			}
		}
	}

	details {
		margin-bottom: 1em;
		
		.details-content {
			margin: 20px 0;
			border-left: 3px solid $light-grey;
			padding: 0 15px;
		}

		summary {
			p {
				font-style: italic;
				display: inline;
			}
		}
	}
	
	blockquote {
		border-left: 3px solid $light-grey;
		margin: 20px 0;
		padding: 0 15px;
		
		color: $dark-grey;
		font-style: italic;
	}
	
	sup {
		font-size: $small;
	}

	.twitter-tweet-rendered {
		margin: auto;
	}

	.article-links {
		padding: 0;
		list-style: none;
		svg {
			fill: $black;
			margin-right: .25em;
		}
	}
}

article, .post, footer nav { // applies to the footer too, now that we're at it!
	a:hover {
		text-decoration: underline;
		text-decoration-skip-ink: auto;
	}
}
