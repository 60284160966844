//---------------------------
//
// ## Footnotes
//
//---------------------------

.footnote {
	&::before {
		content: '[';
		user-select: text;
	}
	
	&::after {
		content: ']';
		user-select: text;
	}
}	

.footnotes {
	margin: 3em 0;

	ol {
		font-size: 0.8em;
		padding-left: 1em !important;
	}

	&::before {
		content: 'Footnotes';
		font-weight: bold;
	}
}


@media print {
	.reversefootnote {
		display: none;
	}
}