@mixin block($block-color) {
	--block-bg-color: #{$block-color};
	--block-border-color: #{darken($block-color, 50%)};
}

.block {
	@include block(#eee);
	padding: 0.3em 2em;
	margin: 1em 0;
	background-color: var(--block-bg-color);
	border-bottom: 1px solid var(--block-border-color);
	border-top: 1px solid var(--block-border-color);

	.header {
		display: block;
		font-family: $title-font;
		font-size: $title-weight-light;
		color: var(--block-border-color);
		font-variant: small-caps;

		.mjx-math {
			font-variant: normal;
		}
	}

	p {
		margin-top: 0.5em;
	}

	&.definition, &.note, &.question {
		@include block($block-blue);
	}

	&.theorem {
		@include block($block-green);
	}

	&.lemma {
		@include block($block-purple);
	}
}
