//---------------------------
//
// ## Footer styles
//
//---------------------------

footer {
	@media print {
		display: none;
	}
	
	text-align: center;
	
	color: $dark-grey;
	
	nav {
		padding: 1em 0 0 0;
	}
	
	ul {
		padding: 0;

		list-style: none;
	}

	li {
		display: inline-block;
		margin: 0 1em;
	}
}

// See partials/_typography.scss for an additional footer style
