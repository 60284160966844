@media print {
	@page {
		size: A4 portrait;
	}

	@page:left {
		@bottom-left {
			content: "Page " counter(page) " of " counter(pages);
		}
	}

	@page:right {
		@bottom-right {
			content: "Page " counter(page) " of " counter(pages);
		}
	}

	a {
		text-decoration: underline;
		color: $black;

		// TODO link printing for extenal links, page # for internal.
	}
}
