table {
	border-collapse: collapse;
	margin: 0 auto;
	
	&, td, th {
		border: 1px solid $black;
	}
	
	td, th {
		padding: 0.5rem;
	}
	
	tbody tr:hover {
		background-color: $light-grey;
	}
}