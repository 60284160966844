//---------------------------
//
// ## Color variables
// Try not to use a color unless it is defined here.
//
//---------------------------

$black: #0f0f0f; // A very dark grey
$white: #fff; // for now, white will be plain white.
$red: #db3a3a; // Alternatively, use #ea4b4b
$dark-grey: #919191;
$light-grey: #d4d4d4;

$block-blue: hsl(195, 36, 91);
$block-green: hsl(100, 36, 91);
$block-purple: hsl(220, 36, 91);
