//---------------------------
//
// ## Hero banner styles
// For the "hero banner", where the name of the blog and an image are being displayed.
//
//---------------------------

header.hero {
	@media print {
		display: none;
	}
	
	position: relative;
	display: flex;
	flex-direction: column;
	
	width: 100%;
	margin: 0;
	padding-bottom: 10em;
	
	background-position: center;
	background-size: cover;
	
	text-shadow: 0 0 5px rgba($black, 0.8);
	color: $white;

	a {
		color: $white;
		text-decoration: none;
	}

	nav {
		display: flex;
		justify-content: flex-end;
		@include media($small-screen) {
			justify-content: space-between;
		}
		
		a {
			opacity: 1;
			transition: opacity $transition-time;
	
			font-family: $title-font;
			font-weight: $title-weight-light;
			
			position: relative;
			padding: 0.5em 1em;
			margin: 1em;
			border: 1px solid $white;
			
			text-decoration: none;
			font-size: $large;
			
			color: $white;
	
			&:hover {
				opacity: 0.7;
			}
		}
	}
	
	.title {
		display: block;
		align-self: center;
		padding-top: 2em;
		
		text-align: center;
		font-family: $title-font;
	}
	
	.title, h1, p {
		width: auto;
		margin: 0;
	}
	
	h1 {
		font-size: $huge;
	}
	
	p {
		font-size: $xx-large;
		font-weight: $title-weight;
		@include media($small-screen) {
			font-size: $x-large;
		}
	}
}
