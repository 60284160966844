//---------------------------
//
// ## Table of contents
// If a table of contents is defined in a post
//
//---------------------------

#markdown-toc {
	font-size: 0.9em;
	padding: 1em;
	display: inline-block;
	background-color: lighten($light-grey, 10%);

	// Print styles:
	page-break-after: always;
	@media print {
		background: none;

		&::before {
			content: "Table of Contents";
			margin: 1em auto;
			font-family: $title-font;
			font-weight: $title-weight;
			font-size: $x-large;
		}
		
		a {
			text-decoration: none;

			&::after {
				content: target-counter(attr(href, url), page);
				float: right;
			}
		}
	}

	// Number sections in table of contents:
	&, ul {
		counter-reset: section;
		list-style: none;
	}

	li {
		display: block;
	}

	li::before {
		padding-right: 0.4em;
		counter-increment: section;
		content: counters(section, ".")". ";
	}

	// Number h1 through h6 if a #markdown-toc is present:
	@for $i from 1 through 6 {
		~ h#{$i} {
			@if $i > 1 {
				counter-increment: h#{$i}counter;
				$numbering: "";
				@for $j from 2 through $i {
					$numbering: $numbering counter(h#{$j}counter) ".";
				}
				&:before {
					content: $numbering $nbsp;
				}
			}
			@if $i < 6 {
				counter-reset: h#{$i + 1}counter;
			}
		}
	}
}
