//---------------------------
//
// ## Code blocks and syntax highlighting
// Styling for rouge syntax highlighting
//
//---------------------------

$code_default-padding: 5px;
$code_default-color: #ccc;

code, pre {
	font-family: $monospace-font;
}

// Line numbers:
.gutter {
	user-select: none;
	cursor: default;
	
	::selection {
		color: $code_default-color;
		background-color: transparent;
	}

	.lineno {
		display: inline-block;
		text-align: right;

		color: $code_default-color;	
	}
}

figure.highlight, div.highlight {
	border: 1px solid $light-grey;

	// For Liquid code blocks, Rouge output a `figure.highlight > div.highlight`.
	// For Markdown code blocks, Rouge simply outputs a `div.highlight`.
	// In the latter case, we do not want to have a second border inside the figure.
	div.highlight {
		border: 0;
	}
}

.highlight {
	margin: 20px 0;
	-webkit-margin-before: 1em;
	
	background-color: $white;

	page-break-inside: avoid;
	
	pre {
		margin: 0;
		padding: 0;
	}

	> pre > code { // The whole block, including linenos if any
		display: block;
		
		padding: $code_default-padding; // add the default padding around the code...
		
		white-space: pre;
		overflow-x: auto;
		word-wrap: normal;

		.code { // If using linenos, this is the code.
			border-left: 1px solid $code_default-color;
			width: 100%;
		}
		
		table, tr, td, th { // If using linenos, this is the container of linenos *and* code.
			margin: -1 * $code_default-padding; // ... but remove the default padding if we're using linenos.
			border: 0;
			background-color: $white;
		}
	}

	// Command prompts ("$" in console mode, corresponding to the
	// `Generic.Prompt` Rouge token) should not be selectable.
	.gp, .gp + .w {
		user-select: none;
	}	

	// Syntax highlighting!
	.c {
		color: #707070;
		font-style: italic;
	} // Comment
	.err {
		color: #a61717;
		background-color: #e3d2d2;
	} // Error
	.k {
		font-weight: bold;
	} // Keyword
	.o {
		font-weight: bold;
	} // Operator
	.cm {
		color: #707070;
		font-style: italic;
	} // Comment.Multiline
	.cp {
		color: #999;
		font-weight: bold;
	} // Comment.Preproc
	.c1 {
		color: #707070;
		font-style: italic;
	} // Comment.Single
	.cs {
		color: #999;
		font-weight: bold;
		font-style: italic;
	} // Comment.Special
	.gd {
		color: #000;
		background-color: #fdd;
	} // Generic.Deleted
	.gd .x {
		color: #000;
		background-color: #faa;
	} // Generic.Deleted.Specific
	.ge {
		font-style: italic;
	} // Generic.Emph
	.gr {
		color: #a00;
	} // Generic.Error
	.gh {
		color: #999;
	} // Generic.Heading
	.gi {
		color: #000;
		background-color: #dfd;
	} // Generic.Inserted
	.gi .x {
		color: #000;
		background-color: #afa;
	} // Generic.Inserted.Specific
	.go {
		color: #888;
	} // Generic.Output
	.gp {
		color: #555;
	} // Generic.Prompt
	.gs {
		font-weight: bold;
	} // Generic.Strong
	.gu {
		color: #aaa;
	} // Generic.Subheading
	.gt {
		color: #a00;
	} // Generic.Traceback
	.kc {
		font-weight: bold;
	} // Keyword.Constant
	.kd {
		font-weight: bold;
	} // Keyword.Declaration
	.kp {
		font-weight: bold;
	} // Keyword.Pseudo
	.kr {
		font-weight: bold;
	} // Keyword.Reserved
	.kt {
		color: #458;
		font-weight: bold;
	} // Keyword.Type
	.m {
		color: #077;
	} // Literal.Number
	.s {
		color: #d14;
	} // Literal.String
	.na {
		color: #008080;
	} // Name.Attribute
	.nb {
		color: #0086b3;
	} // Name.Builtin
	.nc {
		color: #458;
		font-weight: bold;
	} // Name.Class
	.no {
		color: #008080;
	} // Name.Constant
	.ni {
		color: #800080;
	} // Name.Entity
	.ne {
		color: #900;
		font-weight: bold;
	} // Name.Exception
	.nf {
		color: #900;
		font-weight: bold;
	} // Name.Function
	.nn {
		color: #555;
	} // Name.Namespace
	.nt {
		color: #000080;
	} // Name.Tag
	.nv {
		color: #008080;
	} // Name.Variable
	.ow {
		font-weight: bold;
	} // Operator.Word
	.w {
		color: #bbb;
	} // Text.Whitespace
	.mf {
		color: #077;
	} // Literal.Number.Float
	.mh {
		color: #077;
	} // Literal.Number.Hex
	.mi {
		color: #077;
	} // Literal.Number.Integer
	.mo {
		color: #077;
	} // Literal.Number.Oct
	.sb {
		color: #d14;
	} // Literal.String.Backtick
	.sc {
		color: #d14;
	} // Literal.String.Char
	.sd {
		color: #d14;
	} // Literal.String.Doc
	.s2 {
		color: #d14;
	} // Literal.String.Double
	.se {
		color: #d14;
	} // Literal.String.Escape
	.sh {
		color: #d14;
	} // Literal.String.Heredoc
	.si {
		color: #d14;
	} // Literal.String.Interpol
	.sx {
		color: #d14;
	} // Literal.String.Other
	.sr {
		color: #009926;
	} // Literal.String.Regex
	.s1 {
		color: #d14;
	} // Literal.String.Single
	.ss {
		color: #990073;
	} // Literal.String.Symbol
	.bp {
		color: #999;
	} // Name.Builtin.Pseudo
	.vc {
		color: #008080;
	} // Name.Variable.Class
	.vg {
		color: #008080;
	} // Name.Variable.Global
	.vi {
		color: #008080;
	} // Name.Variable.Instance
	.il {
		color: #077;
	} // Literal.Number.Integer.Long
}
