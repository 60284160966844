//---------------------------
//
// # Main style
// This style applies to the home page and to all posts.
//
//---------------------------

@import 'modules/_all';
@import 'partials/_all';

span.date {
  margin-top: .5em;
  font-size: .9em;
  font-family: "Oswald",sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

code {
  font-weight: 900;
}

header.hero nav a {
  background-color: rgba(0, 0, 0, 0.4);
}

#notes-entry-container h2 {
  color: #703a3a;
}

li.new:before { content: "🆕"; margin-left: -1.5em;}
li.updated:before { content: "🆙"; margin-left: -1.5em; }
li.plain:before { content: "●"; margin-left: -1.2em; margin-right: .3em; }

.twitter-tweet { margin-left: 2.0em; }

.note h4 {
  display: block;
}

#notes-entry-container.post hr {
  width: 100%;
}

li > * {
    /* vertical-align: text-top; */
}

table.foo {
  margin-left: 0em;
  border: none;
  width: 75%;
}

table.foo td {
width: 75%;
  border: none;
}
